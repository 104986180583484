import { useNavigate, useParams } from 'react-router-dom';
import * as Icons from '@ant-design/icons';
import React from 'react';
import { FilterDropdownProps, SortOrder } from 'antd/lib/table/interface';
import { Button, Modal } from 'antd';
import { SortableHandle } from 'react-sortable-hoc';
import { useTable } from './useTable';
import { ITableAction, ITableColumn, TTableColumn } from '../../types/layouts/table';
import { getCellColor, replaceCellNullValue } from '../../helpers/table';
import { useApiFetch } from '../api/useApiFetch';
import FilterDropdown from '../../components/table/filter-dropdown';
import NumericFilter from '../../components/table/numeric-filter';
import { IListFilter } from '../../types/api/requests';

const { confirm } = Modal;

const DragHandle = SortableHandle(() => <Icons.MenuOutlined style={{ cursor: 'grab' }} />);

export const useColumns = (table: ReturnType<typeof useTable>) => {
    const api = useApiFetch();
    const navigate = useNavigate();
    const { objectName } = useParams();

    const { tableLayout, ds, modal } = table;
    const { filters, sorters } = ds;

    const acceptHtml = (column: ITableColumn, value: any) => {
        if (typeof column.html === 'string' && value !== null && typeof value !== 'undefined') {
            return React.createElement('div', {
                dangerouslySetInnerHTML: {
                    __html: column.html.replace('{value}', value),
                },
            });
        }
        return replaceCellNullValue(value);
    };

    const getColumnFilteredValue = (dataIndex?: ITableColumn['dataIndex']) => {
        if (typeof dataIndex === 'string') {
            return filters?.find((f) => f.column === dataIndex);
        }
        return null;
    };

    const getColumnSortOrder = (
        dataIndex?: TTableColumn['dataIndex'],
    ): SortOrder => {
        if (typeof dataIndex === 'string') {
            const sorter = sorters?.find((f) => f.column === dataIndex);
            if (sorter) {
                return sorter.desc ? 'descend' : 'ascend';
            }
        }
        return null;
    };

    const onEditClick = (record: any, copy = false) => {
        if (tableLayout.editType === 'modal') {
            table.modal.getLayout({
                layoutLink: `/${objectName}/${tableLayout.actions?.edit.action}/layout?${tableLayout.primaryKey}=${record[tableLayout.primaryKey]}&copy=${copy}`,
            });
            table.modal.show(record[tableLayout.primaryKey] || 'id', copy);
        } else {
            navigate(`/${objectName}/${tableLayout.actions?.edit}/${record[tableLayout.primaryKey]}`);
        }
    };

    const onRowDeleteClick = (record: any) => {
        confirm({
            closable: true,
            title: 'Вы действительно хотите удалить выбранную строку?',
            onOk: () => onRowDeleteConfirmed(record),
            okType: 'danger',
        });
    };

    const onRowDeleteConfirmed = async (record: any) => {
        if (typeof tableLayout.actions?.delete.action === 'string') {
            ds.setLoading(true);

            await api.fetchData(
                `/${objectName}/${tableLayout.actions?.delete.action}/${
                    record[tableLayout.primaryKey]
                }`,
                'delete',
                true,
            );

            ds.updateDataSource();
        }
    };

    const onModalActionClick = (record: any, action: ITableAction) => {
        modal.getLayout({
            layoutLink: action.layoutLink.replace(
                '{objectId}',
                record[action.columnKey ?? tableLayout.primaryKey],
            ),
        });
        modal.show(record[tableLayout.primaryKey] || 'id');
    };

    const onRequestActionClick = async (record: any, action: ITableAction) => {
        if (typeof action.action === 'string' && typeof action.columnKey === 'string') {
            ds.setLoading(true);
            await api.fetchData(
                action.action.replace('{objectId}', record[action.columnKey]),
                'get',
                true,
            );
            ds.updateDataSource();
        }
    };

    const leftActions: ITableColumn[] = [];
    const rightActions: ITableColumn[] = [];

    if (tableLayout.actions instanceof Object) {
        Object.keys(tableLayout.actions).forEach((key) => {
            const action = tableLayout.actions?.[key];
            if (action instanceof Object) {
                switch (key) {
                    case 'copy': {
                        (action.float === 'right' ? rightActions : leftActions).push({
                            className: 'table-actions-column',
                            fixed: action.float,
                            key: 'action-edit',
                            width: 50,
                            render: (_, record: any) => (
                                <Button
                                    icon={<Icons.CopyOutlined />}
                                    onClick={() => onEditClick(record, true)}
                                    type="default"
                                    title="Скопировать"
                                    size="small"
                                />
                            ),
                        });
                        break;
                    }
                    case 'edit': {
                        (action.float === 'right' ? rightActions : leftActions).push({
                            className: 'table-actions-column',
                            fixed: action.float,
                            key: 'action-edit',
                            width: 50,
                            render: (_, record: any) => (
                                <Button
                                    icon={<Icons.EditOutlined />}
                                    onClick={() => onEditClick(record)}
                                    type="default"
                                    title="Редактировать"
                                    size="small"
                                />
                            ),
                        });
                        break;
                    }
                    case 'delete': {
                        (action.float === 'right' ? rightActions : leftActions).push({
                            className: 'table-actions-column',
                            fixed: action.float,
                            key: 'action-delete',
                            width: 50,
                            render: (_, record) => (
                                <Button
                                    disabled={record.actionDeleteDisabled}
                                    icon={<Icons.DeleteOutlined />}
                                    type="default"
                                    title="Удалить"
                                    onClick={() => onRowDeleteClick(record)}
                                    size="small"
                                />
                            ),
                        });
                        break;
                    }
                    default: {
                        // @ts-ignore
                        const Icon = Icons[action.icon];
                        if (action.type === 'modal') {
                            (action.float === 'right' ? rightActions : leftActions).push({
                                className: 'table-actions-column',
                                fixed: action.float,
                                key,
                                render: (_, record) => (
                                    <Button
                                        icon={<Icon />}
                                        type="default"
                                        title={action.title}
                                        onClick={() => onModalActionClick(record, action)}
                                        size="small"
                                        disabled={
                                            typeof action.columnKey === 'string'
                                                ? typeof record[action.columnKey]
                                                  === 'undefined'
                                              || record[action.columnKey] === null
                                                : false
                                        }
                                    />
                                ),
                            });
                        }
                        if (action.type === 'request') {
                            (action.float === 'right' ? rightActions : leftActions).push({
                                className: 'table-actions-column',
                                fixed: action.float,
                                key,
                                width: action.width,
                                render: (_, record) => (
                                    <Button
                                        icon={<Icon />}
                                        type="default"
                                        title={action.title}
                                        onClick={() => onRequestActionClick(record, action)}
                                        size="small"
                                    />
                                ),
                            });
                        }
                        break;
                    }
                }
            }
        });
    }

    const isColumnSortingByDnD = (column: TTableColumn) => (typeof table.tableLayout.sortingByDnD === 'string' ? table.tableLayout.sortingByDnD === column.dataIndex : false);

    const renderColumn = (column: TTableColumn, val: any) => {
        if (isColumnSortingByDnD(column)) {
            return (
                <DragHandle />
            );
        }
        return acceptHtml(column, val);
    };

    const renderTitle = (column: TTableColumn) => {
        if (typeof column.titleHtml === 'string') {
            return React.createElement('div', {
                dangerouslySetInnerHTML: {
                    __html: column.titleHtml,
                },
            });
        }
        return column.title;
    };

    const getFilterDropdown = (props: FilterDropdownProps, column: TTableColumn) => {
        if (typeof column.filterType === 'string') {
            switch (column.filterType) {
                case 'numeric': {
                    return React.createElement(NumericFilter, {
                        ...props,
                        onApply: (selectedKeys: string[], operator: IListFilter['operator']) => ds.onFilterChange({
                            column: column.dataIndex as string,
                            values: selectedKeys,
                            operator,
                        }),
                        filteredValue: getColumnFilteredValue(column.dataIndex),
                    });
                }
                default: {
                    return undefined;
                }
            }
        }
        return React.createElement(FilterDropdown, {
            ...props,
            onApply: (f) => ds.onFilterChange({
                column: column.dataIndex as string,
                values: f,
                operator: column.search ? 'contains' : '=',
            }),
            filteredValue: getColumnFilteredValue(column.dataIndex),
            search: column.search,
            filtersLink: column.filtersLink,
            allFiltersSelected: table.tableLayout.allFiltersSelected || column.allFiltersSelected,
        });
    };

    const tableColumns = [...(ds.tableParams?.columns || tableLayout.columns).filter((el) => !el.hidden)].map((el) => {
        const column: ITableColumn = {
            ...el,
            render: (val) => renderColumn(el, val),
            filteredValue: getColumnFilteredValue(el.dataIndex)?.values || null,
            sortOrder: getColumnSortOrder(el.dataIndex),
            title: () => renderTitle(el),
            // @ts-ignore
            onCell: (record: any) => {
                let className = el.className || '';
                const style: React.CSSProperties = {};
                if (
                    el.color instanceof Object
                    && typeof el.dataIndex === 'string'
                ) {
                    style.backgroundColor = el.color.backgroundColor;
                    style.color = el.color.textColor;
                    className = 'colored-cell';
                }
                if (
                    el.notNullColor instanceof Object
                    && typeof el.dataIndex === 'string'
                    && record[el.dataIndex] !== null
                ) {
                    style.backgroundColor = el.notNullColor.backgroundColor;
                    style.color = el.notNullColor.textColor;
                    className = 'colored-cell';
                }

                if (Array.isArray(tableLayout.cellColors) && typeof el.dataIndex === 'string') {
                    const cellColor = getCellColor(tableLayout.cellColors, el.dataIndex as string, record);
                    if (cellColor) {
                        style.backgroundColor = cellColor.backgroundColor;
                        style.color = cellColor.textColor;
                        className = 'colored-cell';
                    }
                }

                return {
                    className,
                    record,
                    editable:
                        el.editor instanceof Object
                        && typeof tableLayout.columnEditorAction === 'string',
                    editor: el.editor,
                    dataIndex: el.dataIndex,
                    title: el.title,
                    editorAction: tableLayout.columnEditorAction,
                    primaryKey: tableLayout.primaryKey,
                    updateTable: ds.updateDataSource,
                    setTableLoading: ds.setLoading,
                    style,
                    action: el.action,
                    hoverColumn: el.hoverColumn,
                };
            },
        };

        if (el.dataIndex && (el.filters || el.search || typeof el.filterType === 'string')) {
            el.filterDropdown = (e) => getFilterDropdown(e, el);
            el.filterIcon = el.search ? React.createElement(Icons.SearchOutlined) : undefined;
        }

        return column;
    }) as ITableColumn[];

    if (leftActions.length > 0) {
        tableColumns.unshift(...leftActions);
    }

    if (rightActions.length > 0) {
        tableColumns.push(...rightActions);
    }

    return tableColumns;
};
